import React from 'react';
import { Text, StyleSheet } from 'react-native';
import {
  colourConst,
  fontSize,
  fontFamilyConst,
} from '../../../../globalStyles';
import { TextProps } from '../../../../utils/types';

const TextCardLocation = (props: TextProps) => {
  return <Text style={styles.text}>{props.children}</Text>;
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.smallRegular,
    color: colourConst.blue,
    textAlign: 'center',
    lineHeight: 14,
  },
});
export default TextCardLocation;
