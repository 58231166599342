import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  ImageBackground,
  StyleSheet,
  useWindowDimensions,
  Pressable,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Constants from 'expo-constants';
import { PhotosCategory } from '../../types';
import { useSecureContext } from '../../context';
import placeholderImage from '../../../assets/agenda-background/agenda-placeholder.jpg';
import { colourConst, fontFamilyConst } from '../../globalStyles';
const { NEXT_PUBLIC_ASSETS_BASE_URL } = Constants.expoConfig!.extra!;
interface PhotoCategoryProps {
  name: PhotosCategory;
  backgroundImageId: string;
  categoryId: string;
  style?: object;
}

const PhotoCategory: React.FC<PhotoCategoryProps> = ({
  name,
  backgroundImageId,
  categoryId,
  style,
}) => {
  const { width } = useWindowDimensions();
  const circleSize = width * 0.45;
  const navigation = useNavigation();
  const styles = StyleSheet.create({
    container: {
      width: circleSize,
      alignItems: 'center',
      marginBottom: 16,
      marginHorizontal: 8,
    },
    circle: {
      width: circleSize,
      height: circleSize,
      borderRadius: circleSize / 2,
      overflow: 'hidden',
      borderWidth: 3,
      borderColor: 'white',
    },
    circleImage: {
      resizeMode: 'cover',
    },
    name: {
      fontFamily: fontFamilyConst.regular,
      color: colourConst.blue,
      marginTop: 8,
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'center',
    },
  });

  const [backgroundImage, setBackgroundImage] = useState('');
  const { token } = useSecureContext();

  useEffect(() => {
    if (
      backgroundImageId &&
      backgroundImageId !== '00000000-0000-0000-0000-000000000000'
    ) {
      fetch(
        `${NEXT_PUBLIC_ASSETS_BASE_URL}/digital-assets/urls/view/2/${backgroundImageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setBackgroundImage(data?.Url);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setBackgroundImage(placeholderImage);
    }
  }, [backgroundImageId, token]);
  const handlePress = () => {
    navigation.navigate('PhotoGallery', { name: name, id: categoryId });
  };
  return (
    <Pressable style={[styles.container, style]} onPress={handlePress}>
      <ImageBackground
        source={{ uri: backgroundImage }}
        style={styles.circle}
        imageStyle={styles.circleImage}
      />
      <Text style={styles.name}>{name}</Text>
    </Pressable>
  );
};

export default PhotoCategory;
